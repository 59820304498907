import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import MdxLayout from 'components/mdx-layout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <MdxLayout title="Mixins" subline="Backbreeze Sass mixins">
    {children}
  </MdxLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Backbreeze is not a mixin library and therefor only provides two mixins intended for public use.`}</p>
    <h2 {...{
      "id": "bb-utility-class"
    }}><inlineCode parentName="h2">{`bb-utility-class`}</inlineCode></h2>
    <p>{`The `}<inlineCode parentName="p">{`bb-utility-class`}</inlineCode>{` mixin is used under the hood to create all of Backbrezze' utility classes. By default it takes a property configuration map as parameter (see `}<a parentName="p" {...{
        "href": "/configuration/#property-driven-variables"
      }}>{`property-driven variables`}</a>{`) but it can also take a `}<inlineCode parentName="p">{`@content`}</inlineCode>{` block to generate classes with multiple properties. This can be practical to generate custom utility classes for your project.`}</p>
    <h3 {...{
      "id": "usage-with-a-property-configuration-map"
    }}>{`Usage with a property configuration map:`}</h3>
    <p>{`Mandatory keys:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`'name'`}</inlineCode>{`: `}<inlineCode parentName="li">{`<String>`}</inlineCode>{` Defines the generated class name`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`'prop'`}</inlineCode>{`: `}<inlineCode parentName="li">{`<String>`}</inlineCode>{` CSS property used`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`'values'`}</inlineCode>{`: `}<inlineCode parentName="li">{`<Map>`}</inlineCode>{` Property values where each map key maps to the value name used in the class name`}</li>
    </ul>
    <p>{`Optional keys:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`'bps'`}</inlineCode>{`: `}<inlineCode parentName="li">{`<Map>`}</inlineCode>{` If set this will generate a responsive utility classes. Typically this will be set to `}<inlineCode parentName="li">{`$bb-bps`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`'pseudo'`}</inlineCode>{`: `}<inlineCode parentName="li">{`<Map>`}</inlineCode>{` If set this will generate pseudo selector utility classes. Typically this will be set to `}<inlineCode parentName="li">{`$bb-pseudo`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`'important'`}</inlineCode>{`: `}<inlineCode parentName="li">{`<Boolean>`}</inlineCode>{` If set the properties will be made `}<inlineCode parentName="li">{`!important`}</inlineCode></li>
    </ul>
    <h4 {...{
      "id": "example"
    }}>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`// _animation-utility.scss

@include bb-utility-class(
  (
    'name': 'anim',
    'prop': 'animation-name',
    'values': (
      'spin': spin,
      'pulse': pulse,
      'none': none,
    ),
    'bps': $bb-bps // <- Use the general breakpoints,,,
  )
);
`}</code></pre>
    <h3 {...{
      "id": "usage-with-a-content-block"
    }}>{`Usage with a content block:`}</h3>
    <p>{`Mandatory keys:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`'name'`}</inlineCode>{`: `}<inlineCode parentName="li">{`<String>`}</inlineCode>{` Defines the generated class name`}</li>
    </ul>
    <p>{`Optional keys:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`'bps'`}</inlineCode>{`: `}<inlineCode parentName="li">{`<Map>`}</inlineCode>{` If set this will generate a responsive utility classes. Typically this will be set to `}<inlineCode parentName="li">{`$bb-bps`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`'pseudo'`}</inlineCode>{`: `}<inlineCode parentName="li">{`<Map>`}</inlineCode>{` If set this will generate pseudo selector utility classes. Typically this will be set to `}<inlineCode parentName="li">{`$bb-pseudo`}</inlineCode></li>
    </ul>
    <h4 {...{
      "id": "example-1"
    }}>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`// _embed.scss

.embed {
  position: relative;

  &::before {
    content: '';
    display: block;
  }
}

@include bb-utility-class(
  (
    'name': 'embed-16\\\\:9',
    'bps': $bb-bps,
  )
) {
  &::before {
    padding-bottom: 9 / 16 * 100%;
  }
}

@include bb-utility-class(
  (
    'name': 'embed-4\\\\:3',
    'bps': $bb-bps,
  )
) {
  &::before {
    padding-bottom: 3 / 4 * 100%;
  }
}
`}</code></pre>
    <p>{`Usage:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="embed embed-4:3 embed-16:9@md">
  <iframe src="//example.com/video" frameborder="0"></iframe>
  <!-- This embed will be 4:3 ration on mobile and 16:9 on larger screens -->
</div>
`}</code></pre>
    <h2 {...{
      "id": "bb-mq"
    }}><inlineCode parentName="h2">{`bb-mq`}</inlineCode></h2>
    <p>{`The `}<inlineCode parentName="p">{`$bb-mq`}</inlineCode>{` mixin is a convenience mixin that allows you to reference breakpoints defined in the `}<a parentName="p" {...{
        "href": "/configuration/#bb-bps"
      }}>{`global `}<inlineCode parentName="a">{`$bb-mqs`}</inlineCode>{` variable`}</a>{` by name and thus avoid having to hardcode values or do string interpolation.`}</p>
    <p>{`As first parameter it accepts the following:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`<String>`}</inlineCode>{` Will render a `}<inlineCode parentName="li">{`min-width`}</inlineCode>{` media query when matching a key in the global `}<a parentName="li" {...{
          "href": "/configuration/#bb-bps"
        }}><inlineCode parentName="a">{`$bb-mqs`}</inlineCode></a>{` map matching the key's value`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`<Strings>`}</inlineCode>{` Will render `}<inlineCode parentName="li">{`@media #{<String>}`}</inlineCode>{`, useful for `}<inlineCode parentName="li">{`max-width`}</inlineCode>{` or other complex media queries`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`<Number>`}</inlineCode>{` Will render a `}<inlineCode parentName="li">{`min-width`}</inlineCode>{` media query`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`<Null | Boolean>`}</inlineCode>{` Will just render the `}<inlineCode parentName="li">{`@content`}</inlineCode>{` block without a media query`}</li>
    </ul>
    <p>{`The second parameter allows to define the media type and is only used when a string matching `}{`[`}<inlineCode parentName="p">{`$bb-mqs`}</inlineCode>{`]`}{` map key or a number is passed.`}</p>
    <h4 {...{
      "id": "example-2"
    }}>{`Example`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`// _config.scss
$bb-mqs: (
  'sm': 400px,
  'lg': 900px,
);

// _my-custom-module.scss
.my-module {
  color: red;

  @include bb-mq(false) {
    font-size: 30px;
  }

  @include bb-mq('sm') {
    color: purple;
  }

  @include bb-mq(600px) {
    color: yellow;
  }

  @include bb-mq('lg', 'screen') {
    color: blue;
  }

  @include bb-mq('screen and (max-width: #{map-get($bb-mqs, ' sm ') - 1px})') {
    font-size: 22px;
  }

  @include bb-mq('print') {
    color: black;
  }
}
`}</code></pre>
    <p>{`Result:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.my-module {
  color: red;
  font-size: 30px;
}

@media all and (min-width: 400px) {
  .my-module {
    color: purple;
  }
}

@media all and (min-width: 600px) {
  .my-module {
    color: yellow;
  }
}

@media screen and (min-width: 900px) {
  .my-module {
    color: green;
  }
}

@media screen and (max-width: 399px) {
  .my-module {
    font-size: 22px;
  }
}

@media print {
  .my-module {
    color: black;
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      